// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Control from "../../styleguide/components/Control/Control.res.js";
import * as IconClose from "../../styleguide/icons/IconClose.res.js";
import * as Cloudinary from "../../libs/Cloudinary.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ModalHeaderScss from "./ModalHeader.scss";

var css = ModalHeaderScss;

var goBack = (function(){window.history.back();});

function ModalHeader$default(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("img", {
                            className: css.logo,
                            src: Cloudinary.imageUrl("static/datacenters-logo.svg")
                          }),
                      JsxRuntime.jsx(Control.AsLink.make, {
                            color: "White",
                            onClick: goBack,
                            children: JsxRuntime.jsx(IconClose.make, {
                                  size: "LG",
                                  color: "Gray"
                                })
                          })
                    ],
                    className: css.header
                  }),
              className: css.container
            });
}

var $$default = ModalHeader$default;

export {
  css ,
  goBack ,
  $$default as default,
}
/* css Not a pure module */
